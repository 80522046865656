import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import axios from 'axios';

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Declare Standlone Pages here (without header and footer)
const NewExperience = React.lazy(() => import('./views/help/Help'));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const parseJwt = (token) => {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace('-', '+').replace('_', '/');
	return JSON.parse(window.atob(base64));
}

class App extends Component {

  render() {
    let token = sessionStorage.getItem("token"); 
	  //alert(JSON.stringify(parseJwt(token)));
    if(token){	
      let tokenObject = parseJwt(token);
      axios.defaults.token = tokenObject;
      axios.defaults.authToken = token;
      axios.defaults.isAdmin = tokenObject.email.match('admin@bestseniorcareusa.com') || tokenObject.email.match('pulickalß@gmail.com') ? true : false;
      //axios.defaults.headers.common['Authorization'] = token;
    }

    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
			        <Route exact path="/experience" name="Home" render={props => <NewExperience {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
